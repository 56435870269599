import { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import './Error404.css';

class Error404 extends Component {
    
    render(){
        return (
            <div className="Error404">
                <div className='container'>
                    <h1><Trans>404 error</Trans></h1>
                    <p><Trans>PageNotFoundText</Trans></p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Error404);
