import React, { Component } from 'react';
import Webcam from "react-webcam";
import './Photo.css';

class Photo extends Component {
    constructor(props) {
        super(props);
        this.takePhoto = this.takePhoto.bind(this);
        this.webcam = React.createRef();
    }
    takePhoto(){
        const photo = this.webcam.current.getScreenshot();
        this.props.onTaken(photo);
    }
    render(){
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "environment"
        };
        return (
            <main>
                <Webcam
                    audio={false}
                    width={"100%"}
                    height="100%"
                    ref={this.webcam}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize={true}
                    screenshotQuality={0.8}
                    videoConstraints={videoConstraints}
                />
                <button className='Take-photo' onClick={this.takePhoto}>
                    <span>Take a photo</span>
                </button>
            </main>
        )
    }
}

export default Photo;