import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Routes, Route} from "react-router-dom";
import Splash from './Splash';
import Scan from './Scan';
import Authorize from './Authorize';
import Error404 from './Error404';


class App extends Component {
    render(){
       return (
            <Routes>
                <Route path="/:uuid" element={<Splash />} />
                <Route path="/welcome" element={<Authorize />} />
                <Route path="/scan/:type" element={<Scan />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        );
    }
}

export default withTranslation()(App);
