/**
 * Thermolabo Roll sensor scanner
 * Version : 1.0
 * Date: 18/01/23
 */
import React, { Component } from 'react'
import Quagga from 'quagga'
import { isAndroid, isDesktop } from "react-device-detect";
import { Trans, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import mask from './mask.svg';

class Scanner extends Component {
    constructor(props) {
        super(props);
        this.toggleFlash = this.toggleFlash.bind(this);
        this.cancel = this.cancel.bind(this);
        this.manuel = this.manuel.bind(this);
        this.setStatusRed = this.setStatusRed.bind(this);
        this.setStatusGreen = this.setStatusGreen.bind(this);
        this.continue = this.continue.bind(this);
        this.submit = this.submit.bind(this);
        this.barcodeField = React.createRef();
        this.statusField = React.createRef();
        this.ConsigneInterval = "";
        this.state = {
            flashActive: false,
            step : 0,
            barcode: '',
            onError: 0,
        };
    }

    componentDidMount() {
        /**
         * Initialize Quagga for Type-128 barcode detection and scanning
         */
        let captWidth = 3000;
        let captHeight = 2500;
        Quagga.init(
        {
            inputStream: {
                type: 'LiveStream',
                constraints: {
                    width: captWidth,
                    height: captHeight,
                    facingMode: 'environment',
                },
                area: { // defines rectangle of the detection/localization area
                    top: "60%",    // top offset
                    right: "20%",  // right offset
                    left: "20%",   // left offset
                    bottom: "0%"  // bottom offset
                },
                singleChannel: true,
            },
            locator: {
                halfSample: true,
                patchSize: "medium", // x-small, small, medium, large, x-large
            },
            numOfWorkers: 4,
            frequency: 4,
            decoder: {
                readers: ['code_128_reader'],
            },
            locate: true,
        },
        function(err) {
            if (err) {
                return console.log(err)
            }
            Quagga.start()
        },
        )
        
        Quagga.onDetected(this._onDetected);

        if (this.ConsigneInterval === "") {
            this.ConsigneInterval = setInterval(() => {
                if (this.state.step === 1)
                {
                    this.setState({step: 0});
                } else {
                    this.setState({step: 1});
                }
            }, 20000);
        }
    }
    /**
     * Method for getting the screen width
     * @returns Int : The screen width
     */
    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    /**
     * Method for getting the screen height
     * @returns Int : The screen height
     */
    getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }
    /**
     * Toggle activation of mobile flashlight if supported by the terminal
     * @returns void
     */
    toggleFlash() {
        let track = Quagga.CameraAccess.getActiveTrack();
        let active = this.state.flashActive;
        const flash = document.querySelector('.Scan-flashToggle');
        if (active) {
            track.applyConstraints({advanced: [{torch:false}]});
            flash.classList.remove('active');
        } else {
            track.applyConstraints({advanced: [{torch:true}]});
            flash.classList.add('active');
        }
        this.setState({flashActive:!active});
        
    }
    /**
     * Check if color seems black
     * @param {*} color 
     * @returns Boolean : true if Black, false otherwi
     */
    isBlack(color) {
        const min = Math.min(color.r, color.g, color.b);
        const max = Math.max(color.r, color.g, color.b);
        if (
            color.r < 120 &&
            color.g < 120 &&
            color.b < 120 &&
            max - min < 50

        ) {
            return true;
        }
        return false;
    }
    /**
     * Check if color seems grey
     * @param {*} color 
     * @returns Boolean : true if Grey, false otherwise
     */
    isGrey(color) {
        const min = Math.min(color.r, color.g, color.b);
        const max = Math.max(color.r, color.g, color.b);
        if (
            color.r > 0 &&
            color.r < 230 &&
            color.g > 0 &&
            color.g < 230 &&
            color.b > 0 &&
            color.b < 230 &&
            max - min < 60
        ) {
            return true;
        }
        return false;
    }
    /**
     * Check if color seems red
     * @param {*} color 
     * @returns Boolean : true if Red, false otherwi
     */
    isRed(color) {
        if (
            color.r > 100 &&
            color.g < 120 &&
            color.b < 120 &&
            color.r - color.g > 50 && 
            color.r - color.b > 50
        ) {
            return true;
        }
        return false;
    }
    /**
     * Quagga callback called when a barcode is detected.
     * We check if the sensor is well placed and captured
     * @param {*} result 
     */
    _onDetected = result => {
        const canvas = Quagga.canvas.dom.image;
        const image = canvas.toDataURL('image/jpeg', 0.8);
        const context = canvas.getContext('2d', {
            'willReadFrequently': true,
        });
        const point1 = {
            x: Math.round(canvas.width * 0.45),
            y: Math.round(canvas.height * 0.465)
        };
        const point2 = {
            x: Math.round(canvas.width * 0.555),
            y: Math.round(canvas.height * 0.486)
        };
        const point3 = {
            x: Math.round(canvas.width * 0.5),
            y: Math.round(canvas.height * 0.55)
        };
        const control1 = context.getImageData(point1.x, point1.y, 1, 1); //Black
        const control2 = context.getImageData(point2.x, point2.y, 1, 1); // black
        const sensor = context.getImageData(point3.x, point3.y, 1, 1); // center Red or White
        const RGBControl1 = {
            r: control1.data[0],
            g: control1.data[1],
            b: control1.data[2],
        };
        const RGBControl2 = {
            r: control2.data[0],
            g: control2.data[1],
            b: control2.data[2],
        };
        const RGBSensor = {
            r: sensor.data[0],
            g: sensor.data[1],
            b: sensor.data[2],
        };
        if (
            this.isBlack(RGBControl1) &&
            this.isBlack(RGBControl2) && 
            (
                this.isGrey(RGBSensor) ||
                this.isRed(RGBSensor)
            ))
        {
            /**
             * If ok, we complete the result object with the detected status.
             * After we stop the quagga scanner and return the result object to caller
             */
            result.isOnError = this.isRed(RGBSensor);
            result.isManual = false;
            result.image = image;
            Quagga.offDetected(this._onDetected); 
            Quagga.stop();
            clearInterval(this.ConsigneInterval);
            this.ConsigneInterval = "";
            document.getElementById('interactive').innerHTML = "";
            this.props.onDetected(result);
        }
        
    }
    /**
     * This method reset the scanner
     */
    cancel(){
        this.setState({step: 0});
        if (this.ConsigneInterval === "") {
            this.ConsigneInterval = setInterval(() => {
                if (this.state.step === 1)
                {
                    this.setState({step: 0});
                } else {
                    this.setState({step: 1});
                }
            }, 20000);
        }
    }
    /**
     * This method load the manual input form
     */
    manuel(){
        clearInterval(this.ConsigneInterval);
        this.ConsigneInterval = "";
        this.setState({step: 2});
    }
    /**
     * This method define the sensor status to Red on manual submit
     */
    setStatusRed(){
        const buttons = document.querySelectorAll('.manual .field button');
        const activeBtn = document.querySelector('.manual .field button.redStatus')
        const ContinueBtn = document.querySelector('.manual .actions button.continue');
        for (const button of buttons) {
            button.classList.remove('active');
        }
        activeBtn.classList.add('active');
        ContinueBtn.classList.remove('hidden');
        this.setState({onError: 1});
    }
    /**
     * This method define the sensor status to Green on manual submit
     */
    setStatusGreen(){
        const buttons = document.querySelectorAll('.manual .field button');
        const activeBtn = document.querySelector('.manual .field button.greenStatus');
        const ContinueBtn = document.querySelector('.manual .actions button.continue');
        for (const button of buttons) {
            button.classList.remove('active');
        }
        activeBtn.classList.add('active');
        ContinueBtn.classList.remove('hidden');
        this.setState({onError: 2});
    }
    /**
     * Called when manual for is filled and submitted
     */
    continue(){
        const barcode = this.barcodeField.current;
        if (barcode.value !== ''){
            this.setState({
                barcode: barcode.value,
                step: 3
            });
        } else {
            barcode.classList.add('is-danger');
        }
    }
    /**
     * Called when manual for is filled and submitted
     */
    submit(){
        if (this.state.onError > 0) {
            const result = {
                isOnError: (this.state.onError === 1),
                isManual: true,
                codeResult: {
                    code: this.state.barcode
                }
            }
            Quagga.offDetected(this._onDetected); 
            Quagga.stop();
            clearInterval(this.ConsigneInterval);
            document.getElementById('interactive').innerHTML = "";
            this.props.onDetected(result);
        }
    }

    render() {
        let flashButton = '';
        let consigneContent = '';
        let scannerClass = ''; 
        if (isAndroid || isDesktop) {
            flashButton =   <button className='Scan-flashToggle' onClick={this.toggleFlash}>
                                <span>Flash Toggle</span>
                            </button>
        }
        switch (this.state.step) {
            case 0:
                /**
                 * Instructions when scanner is loaded
                 */
                scannerClass = 'scanning';
                consigneContent = <div>
                    <h1><Trans>ShockConsignesTitle</Trans></h1>
                    <ul>
                        <li><Trans>ShockConsigne1</Trans></li>
                        <li><Trans>ShockConsigne2</Trans></li>
                        <li><Trans>ShockConsigne3</Trans></li>
                    </ul>
                </div>
                break;
            case 1:
                /**
                 * Instructions when the user dont have scan the barcode after 15 seconds
                 */
                scannerClass = 'difficult';
                consigneContent = <div>
                    <h1><Trans>ShockDifficultiesTitle</Trans></h1>
                    <p><Trans>ShockDifficultiesText</Trans></p>
                    <div className='actions solo2'>
                        <button className='continue' onClick={this.manuel}>
                            <Trans>ButtonOk</Trans>
                        </button>
                    </div>
                </div>
                break;
            case 2:
                /**
                 * Manual serial number
                 */
                scannerClass = 'manual';
                consigneContent = <div>
                    <div className="field">
                        <label className="label"><Trans>ManualIdentifiant</Trans></label>
                        <div className="control has-icons-left barcode">
                            <input className="input" type="text" ref={this.barcodeField} placeholder="TA27046810" />
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faBarcode} />
                            </span>
                        </div>
                    </div>
                    <div className='actions'>
                        <button className='back' onClick={this.cancel}>
                            <Trans>ButtonCancel</Trans>
                        </button>
                        <button className='continue' onClick={this.continue}>
                            <Trans>ButtonContinue</Trans>
                        </button>
                    </div>
                </div>
                break;
            case 3:
                /**
                 * Manual status inputs form
                 */
                scannerClass = 'manual';
                consigneContent = <div>
                    <h1><Trans>ManualStatusTitle</Trans></h1>
                    <div className="field">
                        <button className='redStatus' onClick={this.setStatusRed}>
                            <Trans>ManualStatusRed</Trans>
                        </button>
                        <button className='greenStatus' onClick={this.setStatusGreen}>
                            <Trans>ManualStatusGreen</Trans>
                        </button>
                    </div>
                    <div className='actions'>
                        <button className='back' onClick={this.cancel}>
                            <Trans>ButtonCancel</Trans>
                        </button>
                        <button className='continue hidden' onClick={this.submit}>
                            <Trans>ButtonContinue</Trans>
                        </button>
                    </div>
                </div>
                break;
            default:
                scannerClass = '';
                consigneContent = '';
        }
        return (
            <main className={scannerClass}>
                <div id="interactive" className="viewport"/>
                <img className='Scan-mask' src={mask} alt="masque" />
                <div className='Scan-Roll-mire'></div>
                { flashButton }
                <div className='Scan-consignes'>
                    <div className='container'>
                        { consigneContent }
                    </div>
                </div>
            </main>
        );
        
    }
}

export default withTranslation()(Scanner)
