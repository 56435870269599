import { Component } from 'react';
import { useParams} from "react-router-dom";
import logo from './logo.png';
import './Splash.css';
import Error404 from './Error404';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Splash extends Component {
   
    render(){
        let params = this.props;
        let uuid = params.params.uuid.match(/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/);
        if (!uuid) {
            return <Error404 />;
        }
        sessionStorage.setItem("uuid", uuid);
        setTimeout(() => { 
            window.location.replace('/welcome');
        }, 3000)
        return (
            <div className="Splash">
                <div className='Splash-logo'>
                    <img src={logo} alt="Logo" />
                </div>
            </div>
        );
    }
}

export default withParams(Splash);
