import { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { isIOS, isSafari } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import './Authorize.css';

function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

class Authorize extends Component {

    constructor(props) {
        super(props);
        this.nextStep = this.nextStep.bind(this);
        this.authorizePosition = this.authorizePosition.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.authorizeCamera = this.authorizeCamera.bind(this);
        this.state = {
            step: props.location.state == null ? 0 :props.location.state.step,
            coords: props.location.state == null ? {} :props.location.state.coords,
            isChecking: false,
            isAuthorized: false,
            onError: false,
            errorMsg: ""
        };
    }
    nextStep() {
        let step = this.state.step;
        if (step === 1) {
            this.checkAuthorizations();
        } else {
            step++;
            this.setState({step: step});
        }
    }
    async checkAuthorizations() {
        this.setState({isChecking: true});
        this.authorizePosition();
        
    }
    authorizePosition(){
        const _this = this;
        if ( navigator.permissions && navigator.permissions.query) {
            //try permissions APIs first
            navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                // Will return ['granted', 'prompt', 'denied']
                const permission = result.state;
                if ( permission === 'granted' || permission === 'prompt' ) {
                    _this.getCurrentLocation();
                } else {
                    if (isIOS && isSafari) {

                        _this.setState({
                            isChecking: false,
                            onError: true,
                            errorMsg: <Trans>You must allow geolocation on your browser. Click on <a href={_this.props.t('AuthorizeAuthLocHelpIOS')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                        });
                    } else {
                        _this.setState({
                            isChecking: false,
                            onError: true,
                            errorMsg: <Trans>You must allow geolocation on your browser. Click on <a href={_this.props.t('AuthorizeAuthLocHelpAndroid')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                        });
                    }
                }
            });
        } else if (navigator.geolocation) {
            this.getCurrentLocation();
        } else {
            this.setState({
                isChecking: false,
                onError: true,
                errorMsg: this.props.t('AuthorizeAuthLocNotSupported')
            });
            return false;
        }
    }

    getCurrentLocation(){
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({coords: position.coords});
                this.authorizeCamera();
            },
            (error) => {
                this.setState({
                    isChecking: false,
                    onError: true,
                    errorMsg: error.message //_this.props.t('AuthorizeAuthLocUnavailable')
                });
            },
            options
        );
    }

    authorizeCamera(){
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({audio: false, video: true}).then((stream) => {
                stream.getTracks().forEach(function(track) {
                    track.stop();
                });
                let step = this.state.step;
                step++;
                this.setState({step: step});
                this.setState({isChecking: false});
            }).catch((err) => {
                if (isIOS && isSafari) {
                    this.setState({
                        isChecking: false,
                        onError: true,
                        errorMsg: <Trans>You must allow the camera on your browser. Click on <a href={this.props.t('AuthorizeAuthCamHelpIOS')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                    });
                    return false;
                } else {
                    this.setState({
                        isChecking: false,
                        onError: true,
                        errorMsg: <Trans>You must allow the camera on your browser. Click on <a href={this.props.t('AuthorizeAuthCamHelpAndroid')} target="_blank" rel='noreferrer'>this link</a> to help you do so.</Trans>
                    });
                    return false;
                }
            })
        } else {
            return {
                success: false,
                message: this.props.t('AuthorizeAuthLocNotSupported')
            }
        }
    }

    render(){
        const onError = this.state.onError;
        const step = this.state.step;
        const isChecking = this.state.isChecking;
        const steps = [
            {id:1,title:this.props.t('AuthorizeStep1Title'), text:this.props.t('AuthorizeStep1Text'), text2:this.props.t('AuthorizeStep1Text2')},
            {id:2,title:this.props.t('AuthorizeStep2Title'), text:this.props.t('AuthorizeStep2Text'), text2:this.props.t('AuthorizeStep2Text2')},
            {id:3,title:this.props.t('AuthorizeStep3Title'), text:this.props.t('AuthorizeStep3Text'), text2:this.props.t('AuthorizeStep3Text2')},
        ];
        let title = steps[step].title;
        let text = steps[step].text;
        let text2 = steps[step].text2;
        if (onError) {
            title = this.props.t('AuthorizeErrorTitle');
            text = this.state.errorMsg;
            text2 = '';
        }

        const buttonLabel = this.props.t('ButtonNext');
        const containerClassname = 'Authorize-slide step-' + step;
        if (!onError) {
            if (isChecking) {
                return (
                    <div>
                        <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                            <main>
                                <div className='Authorize-sliders'>
                                    <div className={containerClassname}>
                                        <div className='content'>
                                            <div className='App-Loader'></div>
                                        </div>
                                    </div>
                                    <div className="flex_row">
                                        {steps.map((data, i) =>
                                        i===step ? (
                                            <span key={data.id} className='active'>.</span>
                                        ) : (
                                            <span key={data.id}>.</span>
                                        )
                                        )}
                                    </div>
                                    
                                </div>
                            </main>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            } else {
                let coordinates = {
                    latitude: 0,
                    longitude: 0,
                };
                if (typeof this.state.coords != 'undefined') {
                    coordinates = {
                        latitude: this.state.coords.latitude,
                        longitude: this.state.coords.longitude,
                    };
                }
                return (
                    <div>
                        <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                            <main>
                                <div className='Authorize-sliders'>
                                    <div className={containerClassname}>
                                        { step === 2 ? (
                                        <div className='content'>
                                            <h1>{title}</h1>
                                            <p className="Authorize-text">{text}</p>
                                            <div className='Authorize-products'>
                                                <div className='Authorize-product'>
                                                    <Link to='/scan/shock' state={{coords: coordinates}}>
                                                        <img src='/shock.png' alt="ThermoShock" title="ThermoShock" />
                                                        <h2><Trans>ThermoShock</Trans></h2>
                                                    </Link>
                                                </div>
                                                <div className='Authorize-product'>
                                                    <Link to='/scan/roll' state={{coords: coordinates}}>
                                                    <img src='/tilt.png' alt="ThermoRoll" title="ThermoRoll" />
                                                    <h2><Trans>ThermoRoll</Trans></h2>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        ) : (
                                        <div className='content'>
                                            <h1>{title}</h1>
                                            <p className="Authorize-text">{text}</p>
                                            {(text2 !== '')?
                                            <p className="Authorize-text">{text2}</p>
                                            :
                                            <span></span>
                                            }
                                        </div>
                                        )}
                                    </div>
                                    <div className="flex_row">
                                        {steps.map((data, i) =>
                                        i===step ? (
                                            <span key={data.id} className='active'>.</span>
                                        ) : (
                                            <span key={data.id}>.</span>
                                        )
                                        )}
                                    </div>
                                    { step < 2 ? (
                                    <div className='navigate'>
                                        <button onClick={this.nextStep}>
                                            {buttonLabel}
                                        </button>
                                    </div>
                                    ) : (
                                        <div className='navigate'></div>
                                    )}
                                </div>
                            </main>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <div className="Authorize" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/fond_blanc.svg'})` }}>
                        <main>
                            <div className='Authorize-sliders'>
                                <div className={containerClassname}>
                                    <div className='content'>
                                        <h1>{title}</h1>
                                        <p className="Authorize-text">{text}</p>
                                        {(text2 !== '')?
                                           <p className="Authorize-text">{text2}</p>
                                        :
                                        <span></span>
                                        }
                                    </div>
                                </div>
                                <div className="flex_row">
                                    {steps.map((data, i) =>
                                    i===step ? (
                                        <span key={data.id} className='active'>.</span>
                                    ) : (
                                        <span key={data.id}>.</span>
                                    )
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className='Landscape'></div>
                </div>
            );
        }
    }
}

export default withTranslation()(withLocation(Authorize));
